import {EnvConfig} from "../../../Shared/Constants/environtmentConstants";

export const recoveryPasswordOfOwner = async (data: string) => {
    const response = await fetch(EnvConfig().winahost_api_url + '/api/owner-reset-password', {
        method: 'POST',
        body: JSON.stringify({email: data})
    });

    return await response.json();
}
