import {EnvConfig} from "../../../Shared/Constants/environtmentConstants";

export const changeOwnerPasswordWithToken = async (token: string, password: string) => {
    const response = await fetch(EnvConfig().winahost_api_url + '/api/owner-change-reset-password/' + token, {
        method: 'POST',
        body: JSON.stringify(password)
    });

    return await response.json();
}
