import {EnvConfig} from "../../../Shared/Constants/environtmentConstants";

export const loginOfOwner = async (data: Object) => {
    const response = await fetch(EnvConfig().winahost_api_url + '/api/owner-login', {
        method: 'POST',
        body: JSON.stringify(data)
    });

    return await response.json();
}
