"use strict";
exports.__esModule = true;
exports.EnvConfig = void 0;
var _a = process.env, environment = _a.REACT_APP_ENVIRONMENT, crypto_token = _a.REACT_APP_CRYPTO_TOKEN, google_places_api_key = _a.REACT_APP_GOOGLE_PLACES_API_KEY, winahost_phone = _a.REACT_APP_WINAHOST_PHONE, winahost_api_url = _a.REACT_APP_WINAHOST_API_URL, public_url = _a.PUBLIC_URL;
var EnvConfig = function () { return ({
    environment: environment,
    crypto_token: crypto_token,
    google_places_api_key: google_places_api_key,
    winahost_phone: winahost_phone,
    winahost_api_url: winahost_api_url,
    public_url: public_url
}); };
exports.EnvConfig = EnvConfig;
