import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {EnvConfig} from "../Shared/Constants/environtmentConstants";

const CleanURL = () => {
    const location = useLocation();
    const navigate = useNavigate();

    function extractedUrl() {
        const params = new URLSearchParams(location.search);
        if (params.has("_gl")) {
            params.delete("_gl");
            navigate(location.pathname + "?" + params.toString(), {replace: true});
        }
    }

    useEffect(() => {
        if (EnvConfig().environment !== "production") {
            extractedUrl();
        }
    }, [location]);

    return null;
};

export default CleanURL;
