import {useLanguageContext} from "../../../../context/languageProvider";

export interface ReviewInterface {
    title: string,
    name: string,
    description: string,
    rating: number
}

const Reviews = () => {

    const lang = useLanguageContext();

    const reviewESCollection: ReviewInterface[] = [
        {
            title: 'Muy satisfecho con Winahost! ',
            name: 'Jorge',
            description: 'Buenas tardes, como cliente tengo mi propiedad para gestión exclusiva en modelo Premium de Winahost y desde aquí quiero agradecer públicamente la excelente gestión que han tenido con todas las reservas de mi propiedad (en total 29) durante este año 2023. Muy contento con mi experiencia como cliente de Winahost. Excelente equipo !!',
            rating: 5
        },
        {
            title: 'Equipo muy eficaz',
            name: 'Jose Antonio Trilla',
            description: 'Muy contento con la eficacia del equipo de Winahost pendientes en todo momento de nuestra necesidad, personal muy cualificado y muy humano ante tus necesidades, sin duda lo recomiendo 100%. Eficaces y cercanos sería la palabra para definir al equipo de Winahost.',
            rating: 5
        },
        {
            title: 'Sorprendida!',
            name: 'Leire',
            description: '¡Encantada y sorprendida!Esta temporada ha ido todo genial: reservas, limpiezas, check-ins...Unos inquilinos se quedaron encerrados a las 10 de la noche y el equipo de winahost ya tenía al chico de mantenimiento allí en 20 minutos! Seguiremos con ellos seguro',
            rating: 5
        },
        {
            title: '100% recomendable!',
            name: 'Eduard Domènech',
            description: 'Hace tiempo se pusieron en contacto con nosotros y desde el primer momento nos encajó su propuesta. Ellos se encargan de todo (check in, check out, limpeza, contacto con los huéspedes, etc). En definitiva, no podemos estar mas satisfechos, gracias a Winahost las reservas de la casa se han duplicado. Lo recomiendo al 100%',
            rating: 5
        }
    ]

    const reviewEnCollection: ReviewInterface[] = [
        {
            title: "Stress-Free Property Management",
            name: "Michael Thompson",
            description: "Winahost completely transformed my rental experience. Before, I spent hours managing guest bookings. Now, they handle everything, and I just collect my earnings. Highly recommend!",
            rating: 5
        },
        {
            title: "Exceptional Customer Service",
            name: "Emily Carter",
            description: "The Winahost team is always available when I need them. Their response time and professionalism are outstanding. I haven't had a single complaint from my guests since I started working with them!",
            rating: 5
        },
        {
            title: "More bookings than ever",
            name: "Daniel Lewis",
            description: "I struggled to get consistent reservations, but since switching to Winahost, my occupancy rate has skyrocketed. Their pricing and visibility strategies have significantly increased my revenue!",
            rating: 5
        },
        {
            title: "Hands-Off, Worry-Free Income",
            name: "Sarah Mitchell",
            description: "I travel a lot for work, so I needed a reliable team to manage my property. Winahost takes care of everything—cleaning, guest communication, and even last-minute emergencies. I finally enjoy passive income without stress!",
            rating: 5
        }
    ];

    return (lang.language === 'es') ? reviewESCollection : reviewEnCollection;

}


export default Reviews;
