import {EnvConfig} from "../../../Shared/Constants/environtmentConstants";

export const getAirDnaRentalizeSummaryData = async (data: Object) => {
    const response = await fetch(EnvConfig().winahost_api_url + '/service/airdna/rentalizerSummary', {
        method: 'POST',
        body: JSON.stringify(data)
    });

    const content = await response.json();
    return content.data;
}
