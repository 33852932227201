import {Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import CustomTextField from "../../../components/customComponents/customTextField";
import {ChangeEvent, useEffect, useState} from "react";
import {LeadInterface} from "../../../interfaces/leadInterface";
import {changePropertyHookData} from "../../../hooks/leadsHook";
import {validationContactFormPage} from "../contact";
import {useTranslation} from "react-i18next";
import {EnvConfig} from "../../../Shared/Constants/environtmentConstants";

interface props {
    lead: LeadInterface,
    setLead: Function,
    validationFormPage: validationContactFormPage,
    setValidationFormPage: Function
}

const PropertyForm = ({lead, setLead, validationFormPage, setValidationFormPage}: props) => {
    const {t} = useTranslation('', {keyPrefix: 'formularies.contact'})

    const [buttons, setButtons] = useState({
        pool: false,
        parking: false,
        terrace: false
    });

    const changeLocationData = (key: string, value: any) => {
        changePropertyHookData(lead, setLead, key, value);
        setValidationFormPage({...validationFormPage, property: true})
    }

    const handleManagementExtras = (extra: string) => {
        if (lead.property.extras) {
            const indexValue = lead.property.extras.indexOf(extra);

            if (indexValue === -1) {
                lead.property.extras.push(extra);
            } else {
                lead.property.extras.splice(indexValue, 1);
            }
        } else {
            lead.property.extras = [extra];
        }

        _changeButtonsState(extra)
    }

    const _changeButtonsState = (key: string) => {
        setButtons({...buttons, [key]: !buttons[key]})
    }

    const changeContactData = (key: string, value: any) => {

        if ((key === 'bedrooms' || key === 'bathrooms' || key === 'accommodates') && value < 0) {
            value = 0;
        }
        changePropertyHookData(lead, setLead, key, value);
    }

    const checkIfContactHasExtras = () => {
        if (lead.property.extras) {
            setButtons(prevButtons => {
                // @ts-ignore
                return lead.property.extras.reduce((updatedButtons, extra) => {
                    return {...updatedButtons, [extra]: true};
                }, {...prevButtons});
            });
        }
    }

    useEffect(() => {
        checkIfContactHasExtras();
    }, []);

    return (
        <Grid container justifyContent={'left'} mt={1} spacing={3}>
            <Grid item xs={12}>
                <Typography variant={'body1'} fontWeight={400} mb={3} className={'contactFormTitle'}
                            dangerouslySetInnerHTML={createMarkup(t('propertyForm.title'))}/>
                <Typography variant={'body1'} mb={3} className={'contactFormSubtitle'}
                            dangerouslySetInnerHTML={createMarkup(t('propertyForm.subTitle'))}/>
            </Grid>
            <Grid item xs={12}>
                <GooglePlacesAutocomplete
                    apiKey={EnvConfig().google_places_api_key}
                    apiOptions={{
                        region: 'es'
                    }}
                    selectProps={{
                        defaultInputValue: lead.property.address,
                        placeholder: t('propertyForm.form.propertyFormInput'),
                        onChange: (e: any) => changeLocationData('address', e.label),
                        instanceId: 'react-select-3-live-region',
                        className: 'selectCustomStyled',
                        classNames: {
                            control: () => {
                                return 'selectInputCustomStyled'
                            },
                            menu: () => {
                                return 'selectMenuCustomStyled';
                            }
                        }
                    }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <CustomTextField fullWidth
                                 type={'number'}
                                 aria-valuemin={0}
                                 value={lead.property.bedrooms}
                                 label={t('propertyForm.form.propertyFormRooms')}
                                 id="propertyDetailRoomContactForm"
                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('bedrooms', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <CustomTextField fullWidth
                                 type={'number'}
                                 value={lead.property.accommodates}
                                 label={t('propertyForm.form.propertyFormPax')}
                                 id="propertyDetailPaxContactForm"
                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('accommodates', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <CustomTextField fullWidth
                                 type={'number'}
                                 value={lead.property.bathrooms}
                                 label={t('propertyForm.form.propertyFormBathrooms')}
                                 id="propertyDetailBathroomsContactForm"
                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('bathrooms', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('propertyForm.form.questions.secondQuestionTitle')}</FormLabel>
                    <RadioGroup aria-label="gender" name="availability" value={lead.property.availability} onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('availability', e.target.value)}>
                        <FormControlLabel value="fulltime" control={<Radio/>}
                                          label={t('propertyForm.form.answers.fullTime')}/>
                        <FormControlLabel value="halftime" control={<Radio/>}
                                          label={t('propertyForm.form.answers.parcialTime')}/>
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                             mb={2}
                            dangerouslySetInnerHTML={createMarkup(t('propertyForm.form.extras.doYouHaveExtras'))}/>
                <Grid container justifyContent={'left'} spacing={2} columns={8}>
                    <Grid item xs={12} sm={2}>
                        <Button variant={`${(buttons.pool) ? 'contained': 'outlined'}`} color="primary"
                                onClick={() => handleManagementExtras('pool')}>{t('propertyForm.form.extras.pool')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button variant={`${(buttons.parking) ? 'contained': 'outlined'}`} color="primary"
                                onClick={() => handleManagementExtras('parking')}>{t('propertyForm.form.extras.parking')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button variant={`${(buttons.terrace) ? 'contained': 'outlined'}`} color="primary"
                                onClick={() => handleManagementExtras('terrace')}>{t('propertyForm.form.extras.terrace')}</Button>
                    </Grid>
                </Grid>
            </Grid>


            <Grid item xs={12} md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('propertyForm.form.questions.firstQuestionTitle')}</FormLabel>
                    <RadioGroup aria-label="owner" name="owner" value={lead.property.owner}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('owner', e.target.value)}>
                        <FormControlLabel value="yes" control={<Radio/>} label={t('propertyForm.form.answers.yes')}/>
                        <FormControlLabel value="no" control={<Radio/>} label={t('propertyForm.form.answers.no')}/>
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('propertyForm.form.questions.thirdQuestionTitle')}</FormLabel>
                    <RadioGroup aria-label="hire" name="hire" value={lead.property.hire}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('hire', e.target.value)}>
                        <FormControlLabel value="yes" control={<Radio/>} label={t('propertyForm.form.answers.yes')}/>
                        <FormControlLabel value="no" control={<Radio/>} label={t('propertyForm.form.answers.no')}/>
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('propertyForm.form.questions.fourthQuestionTitle')}</FormLabel>
                    <RadioGroup aria-label="licence" name="licence" value={lead.property.licence}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('licence', e.target.value)}>
                        <FormControlLabel value="yes" control={<Radio/>} label={t('propertyForm.form.answers.yes')}/>
                        <FormControlLabel value="no" control={<Radio/>} label={t('propertyForm.form.answers.noIllDo')}/>
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default PropertyForm;
