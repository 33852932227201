import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {LanguageProvider} from '../context/languageProvider';
import NotFound from "../sections/notFoundSection/notFound";
import Main from "../sections/mainSection/main";
import Service from "../sections/serviceSection/service";
import Prices from "../sections/pricesSection/prices";
import OnlineSection from "../sections/pricesSection/sections/OnlineSection";
import PremiumSection from "../sections/pricesSection/sections/PremiumSection";
import ProfessionalSection from "../sections/pricesSection/sections/ProfessionalSection";
import Resources from "../sections/resourcesSection/resources";
import Blog from "../sections/blogSection/blog";
import Us from "../sections/usSection/us";
import Contact from "../sections/contactSection/contact";
import Technology from "../sections/serviceSection/sections/technology/technology";
import LoginPage from "../sections/authSection/loginPage";
import {
    ENGLISH_MAIN_ROUTE,
    EN_BLOG,
    EN_CONTACT,
    EN_PRICES,
    EN_RESOURCES,
    EN_SERVICE,
    EN_TECHNOLOGY,
    EN_US,
    EN_PROFESSIONAL,
    MAIN_ROUTE,
    ES_SERVICE,
    ES_PRICES,
    ES_RESOURCES,
    ES_CONTACT,
    ES_BLOG,
    ES_PROFESSIONAL,
    ES_TECHNOLOGY,
    ES_US,
    ONLINE,
    PREMIUM,
    ASTERISK,
    ES_LOGIN_OWNERS,
    LANDING_CONTACT_NOW,
    LANDING_FORM,
    LANDING_QUICK_CONTACT,
    RECOVERY_PASSWORD_OWNERS,
    CHANGE_PASSWORD_OWNERS, ES_LEGAL_TEXT,
    LANDING_FORM_PROPERTIES,
    LANDING_FORM_AT, EN_LEGAL_TEXT, EN_LOGIN_OWNERS, CHANGE_PASSWORD_PROVIDERS,
} from "./constantsRoutes";
import LandingForm from "../sections/landingFormSection/landingForm";
import ContactNowSection from "../sections/landingFormSection/contactNow/contactNowSection";
import QuickContactSection from "../sections/landingFormSection/quickContact/quickContactSection";
import RecoveryPasswordPage from "../sections/authSection/recoveryPasswordPage";
import ChangePasswordPage from "../sections/authSection/changePasswordPage";
import LegalText from "../sections/legalTextsSection/legalText";
import LandingFormProperties from "../sections/landingFormSection/contactProperties/contactProperties";
import LandingFormAT from "../../src/sections/AtSection/ContactAt";
import CleanUrl from "./cleanUrl";
import ChangeProviderPasswordPage from "../sections/authSection/changeProviderPasswordPage";


const Routers = () => {

    return (
        <LanguageProvider>
            <BrowserRouter>
                <CleanUrl/>
                <Routes>

                    <Route path={MAIN_ROUTE} element={<Main/>}/>
                    <Route path={ES_SERVICE} element={<Service/>}/>
                    <Route path={ES_TECHNOLOGY} element={<Technology/>}/>
                    <Route path={ES_PRICES} element={<Prices/>}/>
                    <Route path={ES_PRICES + ONLINE} element={<OnlineSection/>}/>
                    <Route path={ES_PRICES + PREMIUM} element={<PremiumSection/>}/>
                    <Route path={ES_PRICES + ES_PROFESSIONAL} element={<ProfessionalSection/>}/>
                    <Route path={ES_RESOURCES} element={<Resources/>}/>
                    <Route path={ES_BLOG + "/:blogPath"} element={<Blog/>}/>
                    <Route path={ES_US} element={<Us/>}/>
                    <Route path={ES_CONTACT} element={<Contact/>}/>
                    <Route path={ES_LEGAL_TEXT} element={<LegalText/>}/>

                    <Route path={ENGLISH_MAIN_ROUTE} element={<Main/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_SERVICE} element={<Service/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_TECHNOLOGY} element={<Technology/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_PRICES} element={<Prices/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_PRICES + ONLINE} element={<OnlineSection/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_PRICES + PREMIUM} element={<PremiumSection/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_PRICES + EN_PROFESSIONAL} element={<ProfessionalSection/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_RESOURCES} element={<Resources/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_BLOG + "/:blogPath"} element={<Blog/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_US} element={<Us/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_CONTACT} element={<Contact/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_LEGAL_TEXT} element={<LegalText/>}/>

                    <Route path={ES_LOGIN_OWNERS} element={<LoginPage/>}/>
                    <Route path={ENGLISH_MAIN_ROUTE + EN_LOGIN_OWNERS} element={<LoginPage/>}/>
                    <Route path={RECOVERY_PASSWORD_OWNERS} element={<RecoveryPasswordPage/>}/>
                    <Route path={CHANGE_PASSWORD_OWNERS + "/:token"} element={<ChangePasswordPage/>}/>

                    <Route path={CHANGE_PASSWORD_PROVIDERS + "/:token"} element={<ChangeProviderPasswordPage/>}/>

                    <Route path={LANDING_FORM} element={<LandingForm/>}/>
                    <Route path={LANDING_CONTACT_NOW} element={<ContactNowSection/>}/>
                    <Route path={LANDING_QUICK_CONTACT} element={<QuickContactSection/>}/>
                    <Route path={LANDING_FORM_PROPERTIES} element={<LandingFormProperties/>}/>
                    <Route path={LANDING_FORM_AT} element={<LandingFormAT/>} 
/>

                    <Route path={ASTERISK} element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
        </LanguageProvider>
    );
}

export default Routers;